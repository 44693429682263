"use strict";

const url = "https://nagoya-syokikensaku.work";
// const url = "https://ninchisyo.sjc-dev-lx.com";

const configs = {
    "apiUrl": `${url}/api`,
};

export default configs;
